<template>
    <b-modal
      id="editPayeeModal"
      hide-footer
      size="lg"  
      no-close-on-backdrop
    >
    <validation-observer
        ref="editPayeeRules"
        v-slot="{ invalid }"
      >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit()"
      >
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <h2>Editar beneficiaro</h2>
              <p></p>
            </div>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Nombre legal"
              label-for="edit-payee-legal-name"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="legalName"
                rules="required"
              >
                <b-form-input
                  v-model="selectedPayee.legal_name"
                  :state="errors.length > 0 ? false: (valid ? true : null)"
                  placeholder="Nombre legal"
                  name="legalName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="E-mail"
              label-for="edit-payee-e-mail"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="selectedPayee.email"
                  :state="errors.length > 0 ? false: (valid ? true : null)"
                  name="Email"
                  placeholder="E-mail"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="RFC"
              label-for="payee-tax-id"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="taxId"
                rules="required"
              >
                <b-form-input
                  v-model="selectedPayee.tax_id"
                  :state="errors.length > 0 ? false: (valid ? true : null)"
                  name="taxId"
                  placeholder="RFC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Codigo postal"
              label-for="payee-zip-code"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="ZipCode"
                rules="required|zip-code"
              >
                <b-form-input
                  v-model="selectedPayee.tax_zip_code"
                  :state="errors.length > 0 ? false: (valid ? true : null)"
                  name="zipCode"
                  placeholder="Codigo postal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Regimen fiscal"
              label-for="payee-fiscal-regime"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="FiscalRegime"
                rules="required"
              >
                <v-select
                  v-model="selectedPayee.fiscal_regime"
                  :options="taxRegimes"
                  label="name"
                  class="h-10"
                  name="FiscalRegime"
                  :state="errors.length > 0 ? false: (valid ? true : null)"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Cdfi Uso"
              label-for="payee-cfdi-use"
            >
              <validation-provider
                #default="{ errors }"
                name="CfdiUse"
                rules="required"
              >
               <v-select
                  v-model="selectedPayee.cfdi_use"
                  :options="cfdiUses"
                  label="name"
                  class="h-10"
                  name="CfdiUse"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-warning"
              type="reset"
              class="mt-2"
              :to="{ name: 'home' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { required, email, zipCode } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedPayee: {
      type: Object,
      default: () => {},
    },
    establishmentId: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      required,
      email,
      zipCode
    }
  },
  computed: {
    ...mapGetters('catalogsSat', ['taxRegimes', 'cfdiUses'])
  },
  methods: {
    ...mapActions('payees', ['editPayee', 'fetchPayeeTaxes']),
    handleSubmit() {
      this.$refs.editPayeeRules.validate().then((success) => {
        if(success){
          this.editPayee(this.selectedPayee)
            .then(() => {
              this.$bvModal.hide('editPayeeModal')
              this.$swal({
                title: 'El beneficiario se ha editado correctamente',
                icon: 'success',
                timer: 2000,
              })
              this.fetchPayeeTaxes({
                by_establishment: this.establishmentId
              })
          }).catch((err) => {
            const errorMessage = err.response.data
            const formattedErrors = Object.keys(errorMessage).map(key => {
              return errorMessage[key][0]
            })
            this.$swal({
              title: formattedErrors.join(', ') || 'Ha ocurrido un error al editar el beneficiario',
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          })
        }
      })
    }
  },

}
</script>