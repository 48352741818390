var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"editPayeeModal","hide-footer":"","size":"lg","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"editPayeeRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("Editar beneficiaro")]),_c('p')])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre legal","label-for":"edit-payee-legal-name"}},[_c('validation-provider',{attrs:{"name":"legalName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false: (valid ? true : null),"placeholder":"Nombre legal","name":"legalName"},model:{value:(_vm.selectedPayee.legal_name),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "legal_name", $$v)},expression:"selectedPayee.legal_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"edit-payee-e-mail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false: (valid ? true : null),"name":"Email","placeholder":"E-mail"},model:{value:(_vm.selectedPayee.email),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "email", $$v)},expression:"selectedPayee.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"RFC","label-for":"payee-tax-id"}},[_c('validation-provider',{attrs:{"name":"taxId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false: (valid ? true : null),"name":"taxId","placeholder":"RFC"},model:{value:(_vm.selectedPayee.tax_id),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "tax_id", $$v)},expression:"selectedPayee.tax_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Codigo postal","label-for":"payee-zip-code"}},[_c('validation-provider',{attrs:{"name":"ZipCode","rules":"required|zip-code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false: (valid ? true : null),"name":"zipCode","placeholder":"Codigo postal"},model:{value:(_vm.selectedPayee.tax_zip_code),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "tax_zip_code", $$v)},expression:"selectedPayee.tax_zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Regimen fiscal","label-for":"payee-fiscal-regime"}},[_c('validation-provider',{attrs:{"name":"FiscalRegime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticClass:"h-10",attrs:{"options":_vm.taxRegimes,"label":"name","name":"FiscalRegime","state":errors.length > 0 ? false: (valid ? true : null),"reduce":function (option) { return option.value; }},model:{value:(_vm.selectedPayee.fiscal_regime),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "fiscal_regime", $$v)},expression:"selectedPayee.fiscal_regime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Cdfi Uso","label-for":"payee-cfdi-use"}},[_c('validation-provider',{attrs:{"name":"CfdiUse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"h-10",attrs:{"options":_vm.cfdiUses,"label":"name","name":"CfdiUse","reduce":function (option) { return option.value; }},model:{value:(_vm.selectedPayee.cfdi_use),callback:function ($$v) {_vm.$set(_vm.selectedPayee, "cfdi_use", $$v)},expression:"selectedPayee.cfdi_use"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"danger","type":"submit","disabled":invalid}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-warning","type":"reset","to":{ name: 'home' }}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }