<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <header
        class="d-flex flex-wrap align-content-center"
        :class="{
          'justify-content-end': userData.role_name === 'customer',
          'justify-content-between': userData.role_name !== 'customer',
        }"
      >
        <h2 class="grow" v-if="userData.role_name !== 'customer'">Beneficiarios</h2>

        <div class="d-flex align-items-center justify-content-end">
          <b-button
            class="add-button-rounded ml-auto"
            variant="success"
            @click="$bvModal.show('existingPayeesModal')"
          >
            <feather-icon icon="PlusIcon" size="18"/>
          </b-button>
        </div>
      </header>

      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small>
            Aquí puedes dar de alta los datos fiscales de tus clientes, proveedores y empleados. 
          </small>
        </b-col>
      </b-row>
    </div>

    <ul class="beneficiaries-list">
      <li
        v-for="payee in payeeTaxes"
        :key="payee.id"
        class="mb-1"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="32"
            :src="payee.logo"
            :text="avatarText(payee.legal_name)"
            :variant="`light-primary`"
            :alt="payee.legal_name"
          />
          <div class="ml-50">
            <b-link class="font-weight-bold d-block text-break">
              {{ payee.legal_name }}
            </b-link>
            <div>
              <small class="text-muted text-wrap text-break">{{
                payee.email
              }}</small>
            </div>

            <div>
              <small class="text-muted text-wrap text-break">
                {{ payee.tax_id }}
              </small>
            </div>
          </div>

          <b-dropdown
            variant="link"
            no-caret
            class="ml-auto"
            :right="$store.state.appConfig.isRTL"
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleEditPayee(payee)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleStatus(payee)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ payee.active_status === 'active' ? 'Desactivar' : 'Activar' }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleDeletePayee(payee)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </li>
    </ul>

    <b-table
      class="position-relative beneficiaries-table"
      :items="payeeTaxes"
      :fields="tableColumns"
      responsive
      primary-key="id"
      show-empty
      empty-text="No existen registros aún"
    >

      <template #cell(fiscal_regime)="data">
          <b-badge
          size="sm"
          pill
        >
          {{ data.item.fiscal_regime }}
        </b-badge>
      </template>

      <template #cell(cfdi_use)="data">
        <b-badge
          size="sm"
          pill
        >
          {{ data.item.cfdi_use  }}
        </b-badge>
      </template>

      <template #cell(person_type)="data">
        <b-badge
          :variant="data.item.person_type === 'moral' ? 'success' : 'warning'"
          size="sm"
          pill
        >
          {{ data.item.person_type | personType }}
        </b-badge>
      </template>

      <template #cell(active_status)="data">
        <b-badge
          :variant="data.item.active_status === 'active' ? 'success' : 'danger'"
          size="sm"
          pill
        >
          {{ data.item.active_status === 'active' ? 'Activo' : 'Inactivo' }}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="handleEditPayee(data.item)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleStatus(data.item)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">{{ data.item.active_status === 'active' ? 'Desactivar' : 'Activar' }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleDeletePayee(data.item)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Eliminar</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    
    <edit-payee-modal
      :selectedPayee="selectedPayee"
      :establishmentId="establishmentId"
    />

    <add-payee-modal 
      :establishmentId="establishmentId" 
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination
} from 'bootstrap-vue'
import { avatarText } from "@core/utils/filter"
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, zipCode } from '@validations'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import AddressFormModel from '@/@core/components/AddressFormModel.vue'
import EditPayeeModal from './EditPayeeModal.vue'
import AddPayeeModal from './AddPayeeModal.vue'

export default {
  components: {
    avatarText,
    AddressFormModel,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
    BTable,
    BDropdown,
    BDropdownItem,
    EditPayeeModal,
    AddPayeeModal,
    BPagination
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
  data() {
    return {
      required,
      email,
      zipCode,
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedPayee: {},
      tableColumns: [
        {
          label: 'RFC',
          key: 'tax_id',
          sortable: true,
        },
        {
          label: 'Nombre legal',
          key: 'legal_name',
          sortable: true,
        },
        {
          label:"E-mail",
          key:"email",
          sortable: true,
        },
        {
          label: 'Codigo postal',
          key: 'tax_zip_code',
          sortable: true,
        },
        {
          label: 'Regimen fiscal',
          key: 'fiscal_regime',
          sortable: true,
        },
        {
          label: 'Cdfi Uso',
          key: 'cfdi_use',
          sortable: true,
        },
        {
          label: 'Tipo de persona',
          key: 'person_type',
          sortable: true,
        },
        {
          label: 'Estatus',
          key: 'active_status',
          sortable: true,
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
        },
      ],
      establishmentId: null,
      selectedPayee: null
    }
  },
  computed: {
    ...mapGetters('payees', ['payeeTaxes', 'payeeCount', 'pagination']),
    ...mapGetters('catalogsSat', ['taxRegimes', 'cfdiUses'])
  },
  mounted() {
    const establishment = this.userData.scoped_roles.filter(x => x.role_resource_type === 'Establishment') 
    this.establishmentId = establishment.length > 0 ? establishment[0].role_resource_id : null
    this.fetchPayeeTaxes({
      by_establishment: this.establishmentId
    })
    this.fetchTaxRegimes()
    this.fetchCfdiUses()
  },
  methods: {
    ...mapActions('payees', ['registerPayee', 'fetchPayeeTaxes', 'deletePayee', 'editPayee']),
    ...mapActions('catalogsSat', ['fetchTaxRegimes', 'fetchCfdiUses']),
    handleEditPayee(payee){      
      this.selectedPayee = payee
      this.$bvModal.show('editPayeeModal')
    },
    handleStatus(payee){
      this.selectedPayee = {
        ...payee,
        active_status: payee.active_status === 'active' ? 'inactive' : 'active'
      }
      this.editPayee(this.selectedPayee).then(() => {
        this.$swal({
          title: 'Estatus actualizado!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.fetchPayeeTaxes({
          by_establishment: this.establishmentId
        })
      }).catch(err => {
        const errorMessage = err.response.data
        const formattedErrors = Object.keys(errorMessage).map(key => {
          return errorMessage[key][0]
        })
        this.$swal({
          title: formattedErrors.join(', '),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    handleDeletePayee(payee){
      this.$swal({
        title: '¿Estas seguro de eliminar?',
        text: 'Esta acción no se puede revertir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No, cancelar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deletePayee(payee.id).then(res => {
            this.$swal({
              title: 'Beneficiario eliminado!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.fetchPayeeTaxes({
              by_establishment: this.establishmentId
            })
          }).catch(err => {
            const errorMessage = err.response.data
            const formattedErrors = Object.keys(errorMessage).map(key => {
              return errorMessage[key][0]
            })
            this.$swal({
              title: formattedErrors.join(', '),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .beneficiaries-list {
    display: block;
    padding: 0 20px;

    li {
      cursor: pointer;
    }

    @media (min-width: 767.98px) {
      display: none;
    }
  }

  .beneficiaries-table {
    display: none;

    @media (min-width: 767.98px) {
      display: block;
    }
  }

  .add-button-rounded {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
